<template lang="pug">
  .root
    component-header-index
    div(class="container-flex")
      div(class="content-left")
        component-top
      div(class="content-right")
        div(class="right-content-border")
          button(@click="onRefresh()" class="reload-button") <i class="fas fa-sync-alt"></i>
          button(@click="$router.push({ name: 'TopIndex' })" class="close-button") <i class="fas fa-times"></i>
          div(v-if="errorMessageTitle" class="t-c error-message-title") {{ errorMessageTitle }}
          div(v-if="errorMessage" class="t-c error-message-text") {{ errorMessage }}
          // 配信中のライブがある場合
          div(v-if="livingType" class="t-c back-streaming")
            button(@click="onStreamingLive()" class="pink-button" ) 配信に戻る
          div(v-else)
            div(class="stream-title")
              h5 今すぐ配信
              button(:disabled="listEvent.length > 0 || notNormalLiving" @click="onStartLiveNow()" class="normal-button")
            br

            div(class="stream-title" v-if="listNormal.length > 0")
              h5 予定している配信
              div(class="stream-table")
                div(v-for="item in listNormal" class="stream-table-item")
                  div(class="stream-table-item-detail")
                    div(class="stream-table-item-detail-room") {{ roomName }}
                    div 配信開始予定時刻 {{ item.schedule_start_time || item.start_time | moment("YYYY/MM/DD HH:mm") }}
                  div(class="stream-table-item-detail")
                    img(v-if="isButtonShow(item)" @click="listEvent.length > 0 ? onStartLiveEvent(item.id) : onStartLiveNormal()" class="img-button" src="/img/haishin_p.png")
              br

            div(class="stream-title" v-if="listEvent.length > 0")
              h5 参加中のイベント配信
              div(class="stream-table")
                div(v-for="item in listEvent" class="stream-table-item")
                  div(class="stream-table-item-detail")
                    span(v-if="item.type === 't3'" class="red-r-label") OD
                    span(v-if="item.type === 't2'" class="orange-r-label") 準OD
                    div(class="stream-table-item-detail-room") {{ item.name }}
                    div(v-if="listEvent" v-for="item in listEvent" class="stream-table-item")
                      div 配信開始予定時刻 {{ item.schedule_start_time || item.start_time | moment("YYYY/MM/DD HH:mm") }}
                  div(class="stream-table-item-detail")
                    button(@click="onStartLiveEvent()" :disabled="!can_broadcast_event || notEventLiving" class="others-button")
                    img(@click="onDetailEvent(item.id)" class="img-button" src="/img/shousai_p.png")
              br

            div(class="stream-title" v-if="Object.keys(this.clubfun).length > 0")
              h5 クラファン配信予定
              div(class="stream-table")
                div(class="stream-table-item")
                  div(class="stream-table-item-detail")
                    div(class="stream-table-item-detail-room") {{ titleClubfun }}
                    div 配信開始予定時刻 {{ clubfun.schedule_start_time || clubfun.start_time | moment("YYYY/MM/DD HH:mm") }}
                  div(class="stream-table-item-detail")
                    img(v-if="isButtonShow(clubfun.live)" @click="onStartLiveClubfun()" class="img-button" src="/img/haishin_p.png")
                    img(@click="onDetailClubfun(clubfun.id)" class="img-button" src="/img/shousai_p.png")
              br

            div(class="stream-title" v-if="listPaid.length > 0")
              h5 有料配信予定
              div(class="stream-table")
                div(v-for="item in listPaid" class="stream-table-item")
                  div(class="stream-table-item-detail")
                    div(class="stream-table-item-detail-room") {{ titlePay }}
                    div 配信開始予定時刻 {{ item.schedule_start_time || item.start_time | moment("YYYY/MM/DD HH:mm") }}
                  div(class="stream-table-item-detail")
                    button(:disabled="notPayLiving" @click="onStartLivePaid()" class="others-button")
              br

    component-footer-index
</template>

<style src="./Index.scss" lang="scss" scoped>
</style>

<script>
import { getLiveListEventEntryExpiry, getLivePayDetail, getLiveAllListS1, getLiveAllListS3, getLiveClubfunDetail, canLiveClubfun, getPayToShow } from '@/api/live'
import { getRoom } from '@/api/room'
import { serverMoment } from '@/utils/tools'

export default {
  name: "SelectIndex",
  components: {
  },
  data() {
    return {
      listNormal: [],
      listPaid: [],
      clubfun: [],
      listEvent: [],
      can_broadcast_event: false,
      roomName: '',
      titlePay: '',
      titleClubfun: '',
      roomMessage:'',
      errorMessageTitle: '',
      errorMessage: '',
      livingType: '',
      livingId: '',
    }
  },
  props: {},
  computed: {
    notNormalLiving() {
      return this.livingType !=='' && this.livingType !== "NORMAL"
    },
    notEventLiving() {
      return this.livingType !=='' && this.livingType !== "EVENT"
    },
    notClubfunLiving() {
      return this.livingType !=='' && (this.livingType !== "FUND")
    },
    notPayLiving() {
      return this.livingType !=='' && this.livingType !== "PAY"
    },

  },
  watch: {},
  mounted() {},
  async created() {
    await this.init();
  },
  updated() {},
  destroyed() {},
  methods: {
    async init() {
      const ret1 = await getLiveAllListS1();
      const ret2 = await getLiveListEventEntryExpiry();
      const ret3 = await getRoom();
      const ret4 = await canLiveClubfun();
      const ret5 = await getPayToShow();
      const retLiving = await getLiveAllListS3();
      this.roomMessage = ret3.data

      this.listNormal = ret1.data.data.filter(v => v.liveable_type === 'NORMAL').filter(v => (new Date(v.schedule_start_time).getTime()+3600000) > serverMoment()).filter((v, i) => i === 0);
      this.listPaid = ret5.data.data.filter((v, i) => i === 0);
      this.clubfun = ret4.data;
      if (ret2.data.data) {
        this.listEvent = ret2.data.data.filter((v, i) => i === 0);
        this.can_broadcast_event = ret2.data.can_broadcast_event;
      }
      this.roomName = ret3.data.name;

      if (this.listPaid.length > 0) {
        const { data } = await getLivePayDetail(this.listPaid[0].id);
        this.titlePay = data.title;
      }

      if (Object.keys(this.clubfun).length > 0) {
        const { data } = await getLiveClubfunDetail(this.clubfun.id);
        this.titleClubfun = data.title;
      }
      // タイトルデータを記録
      const info = {
        normar_title: this.roomName,
        clubfun_title: this.titleClubfun,
        pay_title: this.titlePay,
      }
      await this.$store.dispatch('live/setStateInfo', info);

      if (retLiving.data && retLiving.data.data[0]) {
        this.livingType = retLiving.data.data[0].liveable_type
        this.livingId = retLiving.data.data[0].id
        this.errorMessageTitle = "配信中"
        this.errorMessage = "終了するまで配信を開始することはできません"
        console.log(retLiving)
      }
    },
    async onRefresh() {
      await this.init();
    },
    onDetailClubfun(liveable_id) {
      let link = this.$router.resolve({ name: 'SettingClubfunDetailIndex', params: { liveable_id } });
      window.open(link.href,'_blank');
    },
    onDetailEvent(id) {
      let link = this.$router.resolve({ name: 'SettingEventManageDetail', params: { id } });
      window.open(link.href,'_blank');
    },
    onStartLiveNow() {
      if(!this.roomMessageCheck()){
        return
      }
      let link = this.$router.resolve({ name: 'LiveNormal' });
      window.open(link.href,'_blank');
    },
    onStartLiveNormal() {
      if(!this.roomMessageCheck()){
        return
      }
      let link = this.$router.resolve({ name: 'LiveNormal', query: { id: this.listNormal[0].id } });
      window.open(link.href,'_blank');

    },
    onStartLivePaid() {
      // if(!this.roomMessageCheck()){
      //   return
      // }
      let link = this.$router.resolve({ name: 'LivePaid', query: { id: this.listPaid[0].id } });
      window.open(link.href,'_blank');
    },
    onStartLiveClubfun() {
      // if(!this.roomMessageCheck()){
      //   return
      // }
      let link = this.$router.resolve({ name: 'LiveClubfun', query: { id: this.clubfun.id } });
      window.open(link.href,'_blank');
    },
    onStartLiveEvent(reserve_id) {
      if(!this.roomMessageCheck()){
        return
      }
      let link = this.$router.resolve({ name: 'LiveEvent', query: { id: this.listEvent[0].id, reserve_id } });
      window.open(link.href,'_blank');
    },
    onStreamingLive() {
      if(this.livingType == "NORMAL"){
        this.$router.push({ name: 'LiveNormal', query: { id: this.livingId } });
      } else if(this.livingType == 'EVENT'){
        this.$router.push({ name: 'LiveEvent', query: { id: this.livingId } });
      } else if(this.livingType == 'FUND'){
        this.$router.push({ name: 'LiveClubfun', query: { id: this.livingId } });
      } else if(this.livingType == 'PAY'){
        this.$router.push({ name: 'LivePaid', query: { id: this.livingId } });
      } else {
        this.$alert("配信中のライブがありません。リロードしてください");
      }
    },
    isButtonShow(LiveItem) {
      const now = new Date().getTime() 
      const scheduleStartTime = new Date(LiveItem.schedule_start_time).getTime() 
      // const startedTime = new Date(LiveItem.start_time).getTime() || 0
      const duration = scheduleStartTime - now
      const isInOneHours = Math.abs(duration/1000) < (60 * 60)
      return isInOneHours
    },
    roomMessageCheck() {
      if(!this.roomMessage.banner_store_path) {
        this.$alert('ルームバナーが未設定のため配信を始めることができません。')
        return false
      } else if (!this.roomMessage.name) {
        this.$alert('ルーム名が未設定のため配信を始めることができません。')
        return false
      }
      return true
    }
  }
};
</script>

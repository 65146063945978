import request from '@/utils/request'

// "data": {
//   "id": 1,
//   "streamer_id": 1,
//   "name": "",
//   "banner_store_path": "",
//   "twitter_text": ""
// },
export function getRoom() {
    return request({
        url: `/room`,
        method: 'get',
    })
}

// {
//   "name":"leiju21n",
//   "banner_store_path":"streamer/1/UuyUfWMOSnuyO1Wy9clHg02dduAvu6cjLvzY0lU5.png",
//   "twitter_text":"dddfdfddfdffdfdfdfdds"
// }
export function setRoom(data) {
    return request({
        url: `/room`,
        method: 'put',
        data
    })
}